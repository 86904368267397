<template>
    <div class="container-fluid">
        
        <div class="block">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>標題(中文)</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入標題(中文)"
                            maxlength="50"
                            v-model.trim="healthInfo.TitleChinese"
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>標題(英文)</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入標題(英文)"
                            maxlength="100"
                            v-model.trim="healthInfo.TitleEnglish"
                        />
                    </div>
                </div>

                <div class="col-md-1">
                    <div class="form-group">
                        <label>是否啟用</label>
                        <el-switch
                            v-model="healthInfo.Enabled"
                            active-color="#13ce66"
                        >
                        </el-switch>
                    </div>
                </div>
                
                <div class="col-md-2">
                    <div class="form-group">
                        <label>產生QRCode</label>
                        <eva-icon fill="#000" name="external-link" class="icon" @click="showQrcode" />
                    </div>
                </div>
            </div>
        </div>
        

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>足跡</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getFootprintPages(1, 10)"
                            v-model.trim="footprintKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getFootprintPages(1, 10)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            data-toggle="modal"
                            data-target="#footprintModal"
                            @click="commonModal(true)"
                        >
                        新增足跡
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="footprintRows" :columns="footprintColumns" :config="config" :classes="classes" @on-change-query="onChangeFootprint" :total-rows="footprintTotal">

                <template slot="name" slot-scope="props">
                <span :title="props.row.English">{{ props.row.Chinese }}</span>
                </template>

                <template slot="edit" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#footprintModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Edit") }}
                </button>
                </template>

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delFootprintModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>調查類別</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getSurveyItemPages(1, 10)"
                            v-model.trim="surveyKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-10">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getSurveyItemPages(1, 10)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            @click="goSurveyItem(null)"
                        >
                        新增調查類別
                        </button>
                        <span class="btn text-danger" style="margin-top: 30px; margin-left: 10px;cursor: auto;font-size: .9em;" v-if="isFilter">※使用關鍵字查詢時無調整排序功能，若需調整排序請將關鍵字欄位清空查詢</span>
                        <!-- <button class="info btn" style="margin-top: 30px; margin-left: 10px" data-toggle="tooltip" data-placement="top" title="使用關鍵字查詢時無調整排序功能，若需調整排序請將關鍵字欄位清空查詢">
                            i
                        </button> -->
                    </div>
                </div>                
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="surveyItemRows" :columns="surveyItemColumns" :config="config" :classes="classes" @on-change-query="onChangeSurveyItem" :total-rows="surveyItemTotal">

                <template slot="sort" slot-scope="scope">
                    <div class="text-center">
                        <button
                            v-if="scope.row.id !== 1"
                            class="btn btn btn-outline-secondary btn-sm"
                            @click="changeSort(scope.row.Guid, 'up')"
                            style="margin: 0 2px"
                        >
                            <i class="fas fa-long-arrow-alt-up"></i>
                        </button>
                        <button
                            v-if="scope.row.id !== surveyItemTotal"
                            class="btn btn btn-outline-secondary btn-sm"
                            @click="changeSort(scope.row.Guid, 'down')"
                            style="margin: 0 2px"
                        >
                            <i class="fas fa-long-arrow-alt-down"></i>
                        </button>
                    </div>
                </template>

                <template slot="edit" slot-scope="props">
                    <button
                        class="btn btn-info btn-sm"
                        style="margin-right: 0.5rem"
                        @click="goSurveyItem(props.row.Guid)"
                    >
                        {{ i18n("Buttons.Edit") }}
                    </button>
                </template>

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delSurveyItemModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <!-- fiexed button -->
        <button class="btn btn-success" id="save" @click.prevent="saveExternalHealthQnrDesign">
            <!-- <i class="fa fa-angle-up"></i> -->
            儲存
        </button>

        <!-- 刪除調查類別 Modal -->
        <div
            id="delSurveyItemModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">刪除調查類別</div>
                <div class="modal-body">
                    確認刪除
                    <span style="font-weight: bolder; color: red">
                    {{ item.BuildingChinese }} </span
                    >?
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                    {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="deleteSurveyItem">
                    {{ i18n("Buttons.Delete") }}
                    </button>
                </div>
            </div>
        </div>
        </div>

        <!-- 新增&編輯足跡 Modal -->
        <div
        id="footprintModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <ValidationObserver
            ref="footprint"
            class="modal-content"
            v-slot="{ handleSubmit, reset }"
            >
            <form @reset.prevent="reset">
                <div class="modal-header">
                <span v-if="isAdd">新增足跡</span>
                <span v-else>編輯足跡</span>
                </div>
                <div class="modal-body">
                <!-- <div class="field">
                    <ValidationProvider
                        tag="div"
                        :name="i18n('Role.RoleName')"
                        :rules="`my_not:${nowItem.Name}|required`"
                        v-slot="{ errors }"
                    >
                        <label>
                        <span class="mark">*</span>足跡時間
                        </label>
                        <input
                        type="text"
                        class="form-control"
                        />
                        <span v-if="errors[0] && !is_composing" class="text-danger">
                        {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                    </div> -->

                <div class="field">
                    <ValidationProvider
                    tag="div"
                    rules="required"
                    v-slot="{ errors }"
                    >
                    <label>
                        <span class="mark">*</span>足跡名稱
                        <!-- <span class="mark">(不可重複)</span> -->
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="item.Chinese"
                    />
                    <span v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                    </span>
                    </ValidationProvider>
                </div>

                <div class="field">
                    <ValidationProvider
                    tag="div"
                    :name="i18n('Role.RoleName')"
                    :rules="`my_not:${nowItem.Name}|required`"
                    v-slot="{ errors }"
                    >
                    <label>
                        <span class="mark">*</span>足跡英文名稱
                    </label>
                    <textarea class="form-control" cols="30" rows="5" v-model="item.English"></textarea>
                    <span v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                    </span>
                    </ValidationProvider>
                </div>
                </div>
                <div class="modal-footer">
                <button
                    type="reset"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    {{ i18n("Buttons.Cancel") }}
                </button>
                <button
                    type="button"
                    class="btn btn-success"
                    @click="handleSubmit(saveFootprint(false))"
                >
                    {{ i18n("Buttons.Save") }}
                </button>
                <button
                    v-if="isAdd"
                    type="button"
                    class="btn btn-success"
                    @click="handleSubmit(saveFootprint(true))"
                >
                    {{ i18n("Buttons.SaveAndContinue") }}
                </button>
                </div>
            </form>
            </ValidationObserver>
        </div>
        </div>

        <!-- 刪除足跡 Modal -->
        <div
        id="delFootprintModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">刪除足跡</div>
            <div class="modal-body">
                確認刪除
                <span style="font-weight: bolder; color: red">
                {{ item.Name }} </span
                >?
            </div>
            <div class="modal-footer">
                <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                {{ i18n("Buttons.Cancel") }}
                </button>
                <button type="button" class="btn btn-danger" @click="delFootprint">
                {{ i18n("Buttons.Delete") }}
                </button>
            </div>
            </div>
        </div>
        </div>

        <!--顯示Qrcode-->
        <div id="qrcodeModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div
                    style="display:block; text-align:center; color:#FFF; padding:20px;"
                >
                    QRcode
                </div>
                    <div class="modal-body">
                        <template>
                            <qrcode-vue
                                id="qrcode"
                                :value="QRcode"
                                :size="size"
                                class="qrcode-wrap"
                                level="H"
                            />
                        </template>
                        <div>
                            <!-- <span style="color: white;font-size: .8em;">{{ QRcode }}</span> -->
                            <input
                                v-if="QRcode"
                                id="copyText"
                                type="text"
                                size="5"
                                style="opacity: 0;width: 100%;"
                                v-model="QRcode"
                            />
                        </div>
                        <div style="margin-top: 12px">
                            <button
                                class="btn btn-primary mr-2"
                                @click.prevent="downloadQRCode(`疫調問卷`)"
                            >
                                下載
                            </button>
                            <button
                                ref="copyButton"
                                class="btn btn-info mr-2"
                                data-clipboard-target="#copyText"
                                @click="initClipboard"
                            >
                                <span style="color: #fff">
                                {{ i18n("Basic.CopyLink") }}
                                </span>
                            </button>
                            <button
                                type="reset"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {{ i18n("Buttons.Cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters, mapActions } from "vuex";
import { apiGetExternalFootprintPages, apiAddExternalFootprint, apiEditExternalFootprint } from '@/apis/footprint'
import { apiGetExternalHealthQnrDesign, apiSaveExternalHealthQnrDesign, apiGetSurveyItemPages, apiDeleteSurveyItem, apiSortSurveyItem } from '@/apis/externalQnrManage'

import QrcodeVue from "qrcode.vue";
import Clipboard from "clipboard";
// import CommonModal from '@/components/Modal/commonModal.vue'

export default {

  components: {
    QrcodeVue
  },

  data() {
    return {

      // Modal狀態
      isAdd: false,

      // 判斷角色名稱是否重複
      item: {
          BuildingGuid: null
      },
      nowItem: {},

      healthInfo: {
        TitleChinese: null,
        TitleEnglish: null,
        DescriptionChinese: null,
        DescriptionEnglish: null,
        CompletedChinese: null,
        CompletedEnglish:null,
        Enabled: false,
      },
      
      footprintKeyword: null,
      surveyKeyword: null,

      footprintRows: [],
      footprintColumns: [
        {
          label: "足跡名稱",
        //   name: "Name",
          slot_name: "name",
        },
        {
          label: "足跡名稱(英文)",
          name: "English",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      footprintTotal: 0,

      surveyItemRows: [],
      surveyItemColumns: [
        {
          label: "順序",
          name: "sort",
        },
        {
          label: "調查類別名稱(中文)",
          name: "SurveyChinese",
        },
        {
          label: "調查類別名稱(英文)",
          name: "SurveyEnglish",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      surveyItemTotal: 0,

      config: {
          ...config,
          server_mode: true,
      },
      classes: {
          tableWrapper: "table-responsive footprint-table",
          cell: {
              "text-left" : true
          },
      },
      footprintTableInfo: {
          // 分頁資訊
          page: 1,
          per_page: 10,
      },
      surveyItemTableInfo: {
          // 分頁資訊
          page: 1,
          per_page: 10,
      },

      areaOptions: [],

      QRcode: '',
      QRCodeItem: {},
      size: 300,

      clipboard: null,

      isFilter: false,  //  判斷是否有查詢調查類別
    };
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  watch: {
    lang: function() {

    },
    isFilter: function() {
        if(this.isFilter) {
            this.surveyItemColumns = [
                {
                    label: "調查類別名稱(中文)",
                    name: "SurveyChinese",
                },
                {
                    label: "調查類別名稱(英文)",
                    name: "SurveyEnglish",
                },
                {
                    label: "",
                    slot_name: "edit",
                },
                {
                    label: "",
                    slot_name: "delete",
                },
            ]
        } else {
            this.surveyItemColumns = [
                {
                    label: "順序",
                    name: "sort",
                },
                {
                    label: "調查類別名稱(中文)",
                    name: "SurveyChinese",
                },
                {
                    label: "調查類別名稱(英文)",
                    name: "SurveyEnglish",
                },
                {
                    label: "",
                    slot_name: "edit",
                },
                {
                    label: "",
                    slot_name: "delete",
                },
            ]
        }
    }
  },

  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    //  新增&編輯&刪除 Modal
    commonModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        // 原data
        this.nowItem = {
          ...item,
        };
        this.isAdd = false;
      } else {
        // this.item = {BuildingGuid: null};
         this.item = {};
        this.isAdd = true;
      }
      this.$refs.footprint.reset();
    },

    // 顯示 QRcode
    showQrcode(item) {
        const url = window.location.origin;
        
        this.QRcode = `${url}/#/external_precautions`;
        if(this.QRcode !== '') {
            $('#qrcodeModal').modal('show')
        }
        this.QRCodeItem = item
    },

    //  複製套件 初始化 (避免 success.length > 0)
    initClipboard() {
        //成功
        this.clipboard.on("success", () => {
            this.$notify({
                title: "成功",
                message: "成功複製連結",
                type: "success",
                duration: 1500,
            });

        });
        setTimeout(() => {
            this.clipboard.destroy();
            this.clipboard = new Clipboard(this.$refs.copyButton);
        }, 100)
    },

    onChangeFootprint(queryParams) {
        const vm = this;

        vm.footprintTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };

        vm.getFootprintPages(
            vm.footprintTableInfo.page,
            vm.footprintTableInfo.per_page,
        );

    },
    onChangeSurveyItem(queryParams) {
        const vm = this;

        vm.surveyItemTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };
        vm.getSurveyItemPages(queryParams.page,  queryParams.per_page)

    },

    //  查詢足跡
    getFootprintPages(page = 1, per_page = 10) {

      this.updateLoading(true)
      apiGetExternalFootprintPages({
          Page: page,
          PerPage: per_page,
          Keyword: this.footprintKeyword,
      }).then((response) => {

          if(response.status === 200) {
            this.footprintRows = response.data.Dtos
            this.footprintTotal = response.data.DataQuantity
          }

          this.updateLoading(false)
      })
    },

    //  新增足跡
    addFootprint(isContinue) {

        this.$refs.footprint.validate().then((success) => {
            if (success) {
                // 證成功後的行為包含 AJAX傳送、重製表單等等
                this.updateLoading(true)
                apiAddExternalFootprint({
                    ...this.item
                }).then((response) => {

                    if(response.status === 200) {
                        this.item = {};
                        if(!isContinue) {
                            $("#footprintModal").modal("hide");
                        } else {
                            this.$refs.footprint.reset();
                        }

                        this.getFootprintPages(
                            this.footprintTableInfo.page,
                            this.footprintTableInfo.per_page,
                        );

                        this.$notify({
                            title: "成功",
                            message: "成功新增足跡",
                            type: "success",
                            duration: 3500,
                        });
                    }

                    this.updateLoading(false)
                })
            } else {
                // 驗證失敗產生的行為
            }
        });

    },

    //  編輯足跡
    editFootprint() {

        this.$refs.footprint.validate().then((success) => {
            if (success) {
                // 證成功後的行為包含 AJAX傳送、重製表單等等
                this.updateLoading(true)
                apiEditExternalFootprint({
                    ...this.item
                }).then((response) => {

                    if(response.status === 200) {
                        this.item = {};
                        $("#footprintModal").modal("hide");

                        this.getFootprintPages(
                            this.footprintTableInfo.page,
                            this.footprintTableInfo.per_page,
                        );

                        this.$notify({
                            title: "成功",
                            message: "成功編輯足跡",
                            type: "success",
                            duration: 3500,
                        });
                    }

                    this.updateLoading(false)
                })
            } else {
                // 驗證失敗產生的行為
            }
        });

    },

    saveFootprint(isContinue) {
      if(this.isAdd) {
        this.addFootprint(isContinue)
      } else {
        this.editFootprint()
      }
    },

    //  刪除足跡
    delFootprint() {
      const vm = this;
      const api = `${window.BaseUrl.api}/ExternalQnr/DeleteFootprint/${vm.item.ID}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {

          if(response.status === 200) {
            this.item = {};
            $("#delFootprintModal").modal("hide");

            this.getFootprintPages(
                this.footprintTableInfo.page,
                this.footprintTableInfo.per_page,
            );

            this.$notify({
                title: "成功",
                message: "成功刪除足跡",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },



    //  取得調查項目
    getSurveyItemPages(page = 1, per_page = 10) {
        apiGetSurveyItemPages({
            Keyword: this.surveyKeyword,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            
            if(response.status === 200) {

                if(this.surveyKeyword === null || this.surveyKeyword.length === 0) {
                    this.isFilter = false
                } else {
                    this.isFilter = true
                }

                this.surveyItemRows = response.data.Dtos.map((item, index) => {
                    return {
                        ...item,
                        id: this.surveyItemTableInfo.page === 1 ? index + 1 : index + 1 + ((this.surveyItemTableInfo.page - 1) * this.surveyItemTableInfo.per_page)
                    }
                })
                this.surveyItemTotal = response.data.DataQuantity
            }
        })
    },

    //  刪除調查項目
    deleteSurveyItem() {
      this.updateLoading(true)
      apiDeleteSurveyItem({
        Guid: this.item.Guid,
        UpdateUser: this.$cookies.get("userId")
      }).then((response) => {

          if(response.status === 200) {
            $("#delSurveyItemModal").modal("hide");

            this.getSurveyItemPages(
                this.surveyItemTableInfo.page,
                this.surveyItemTableInfo.per_page,
            )

            this.$notify({
                title: "成功",
                message: "成功刪除調查項目",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
    },


    //  儲存座位號碼
    saveSeatNo() {
        if(this.isAdd) {
            this.addSeatNo()
        } else {
            this.editSeatNo()
        }
    },

    //  最新外部疫調問卷設計
    getExternalHealthQnrDesign() {
        
        this.updateLoading(true)
        apiGetExternalHealthQnrDesign()
        .then((response) => {

            if(response.status === 200) {
                this.healthInfo = response.data
            }

            this.updateLoading(false)
        })
        .catch(() => {
            this.updateLoading(false)
        })
    },

    //  儲存外部疫調問卷設計
    saveExternalHealthQnrDesign() {

        this.updateLoading(true)
        apiSaveExternalHealthQnrDesign({
            ...this.healthInfo,
            SavedUser: this.$cookies.get("userId")
        })
        .then((response) => {

            if(response.status === 200) {
                this.$notify({
                    title: "成功",
                    message: "成功儲存",
                    type: "success",
                    duration: 3500,
                });

                this.getExternalHealthQnrDesign();
            }

            this.updateLoading(false)
        })
    },
    
    downloadQRCode(filename) {  //  下載QRCode
        let canvas = document.getElementsByTagName('canvas')

        let a = document.createElement('a')
        a.href = canvas[0].toDataURL();
        a.download = filename || 'QRCode.png'

        a.click()

    },

    //  go 調查項目
    goSurveyItem(guid) {
        this.$router.push({ path: "/manager/questionnaire_management/externalqnr_manage/design", query: { Guid: guid } })
    },

    //  排序
    changeSort(guid, status) {
      const vm = this;

      const data = {
        Guid: guid,
        Turn: status,
      };

      apiSortSurveyItem({
          ...data
      }).then((response) => {
          if (response.status === 200) {
            vm.$notify({
              title: "成功",
              message: "順序調整成功",
              type: "success",
              duration: 2000,
            });
            vm.getSurveyItemPages(
              vm.surveyItemTableInfo.page,
              vm.surveyItemTableInfo.per_page,
            );
          }
      })
      .catch((error) => {
            vm.$notify({
                title: "失敗",
                message: error.response.data,
                type: "error",
                duration: 3500,
            });
      });
    },

  },

  mounted() {
      this.getExternalHealthQnrDesign()

      this.getFootprintPages()
      this.getSurveyItemPages()

      this.clipboard = new Clipboard(this.$refs.copyButton);

      $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        // $('[data-toggle="popover"]').popover()
      })
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>



<style lang="scss" scoped>
.search-card {
    margin-bottom: 0;

    .card-body {
        padding: 1.25rem;
        padding-bottom: 0;
    }
}

.yahome-table-wrap {
    padding: 0 20px !important;
    border-radius: 0px;
}

.block {
    padding: 20px;
    padding-bottom: 0;
    background-color: #fff;
}

.row .form-group label {
    font-size: 0.8em;
}

#qrcodeModal {
    .modal-content {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .modal-body {
        text-align: center;

        .qrcode-wrap {
            display: inline-block;
            background-color: #fff;
            padding: 10px;
            border-radius: 5px;
        }
    }
}

.form-group {
    /deep/ .el-switch {
        width: 100%;
        height: 38px;
    }

    /deep/ .icon {
        width: 100%;
        height: 38px;
        vertical-align: sub;
        cursor: pointer;
    }
}

.info {
    width: 30px;
    height: 30px;
    padding: 6px;
    border: none;
    background-color: #6c757d;
    color: #fff;
    border-radius: 100%;
    line-height: 16px;
}
</style>
